import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "./layout"
import Seo from "./seo"

import "../css/event-page.scss"
import { StaticImage } from "gatsby-plugin-image"
import AppleBadge from "../images/svg/apple-badge.svg" // ignore VSCode warning - it works

const EventLandingPage = () => (
  <Layout mainClass="d-flex">
    <Seo title="Event | {name}" bodyClass="event" />
    <div id="main-event">
      <StaticImage
        src="../images/toureka-logo-on-white.png"
        width={400}
        quality={100}
        // className="ios badge"
        // formats={["AUTO", "WEBP", "AVIF"]}
        alt="Toureka!"
        // style={{ marginBottom: `1.45rem` }}
      />
      <h2 style={{ marginBottom: 0 }}>Available on:</h2>
      <div className="logos">
        <a href="https://apps.apple.com/us/app/APP_ID">
          {/* <img
                className="ios badge"
                alt="Download on the App Store"
                src="/images/apple-badge.svg"
              /> */}
          <AppleBadge className="ios badge" />
          {/* <StaticImage
                src="../images/svg/apple-badge.svg"
                // width={300}
                quality={100}
                className="ios badge"
                // formats={["AUTO", "WEBP", "AVIF"]}
                alt="A Gatsby astronaut"
                // style={{ marginBottom: `1.45rem` }}
              /> */}
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.toureka.app.prod">
          <img
            className="android badge"
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>
    </div>
  </Layout>
)

export default EventLandingPage
