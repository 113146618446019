//
// FOR SPARK
//
import * as React from "react"

import "../../css/landing.scss"
import "../../css/legal.scss"
import EventLandingPage from "../../components/event-landing-page"

const AppLandingPage = () => <EventLandingPage />

export default AppLandingPage
